var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "comSelect-container" },
    [
      _c(
        "SNOWS-TreeSelect",
        _vm._g(
          {
            attrs: {
              options: _vm.treeData,
              placeholder: _vm.placeholder,
              clearable: _vm.clearable,
              disabled: _vm.disabled,
              lastLevel: "",
              lastLevelKey: "type",
              multiple: _vm.multiple,
              lastLevelValue: "user",
            },
            model: {
              value: _vm.innerValue,
              callback: function ($$v) {
                _vm.innerValue = $$v
              },
              expression: "innerValue",
            },
          },
          _vm.$listeners
        )
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }