<template>
    <keep-alive :include="cachedViews">
      <router-view class="avue-view" />
    </keep-alive>
</template>
<script >
import {mapGetters} from "vuex";

export default {
  name:'layout',
  data(){
    return {

    }
  },
  computed:{
    ...mapGetters(['tagList']),
    cachedViews(){
      return this.tagList.filter(item=>item.meta.code).map(item=>item.meta.code)
    },
  },
}
</script>
