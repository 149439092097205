var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "UploadFile-container" },
    [
      _c(
        "el-upload",
        {
          attrs: {
            action: _vm.define.comUploadUrl + "/" + _vm.type,
            headers: _vm.uploadHeaders,
            "on-preview": _vm.handlePreview,
            "on-remove": _vm.handleRemove,
            "before-remove": _vm.beforeRemove,
            "on-success": _vm.handleSuccess,
            "file-list": _vm.fileList,
            accept: _vm.accept,
          },
        },
        [
          _c(
            "el-button",
            { attrs: { size: "small", icon: "el-icon-upload" } },
            [_vm._v("选择文件")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }