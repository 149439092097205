var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "singleImg-container" },
    [
      _vm.imageUrl
        ? _c(
            "div",
            { staticClass: "el-upload-list el-upload-list--picture-card" },
            [
              _c(
                "li",
                { staticClass: "el-upload-list__item is-success" },
                [
                  _c("el-image", {
                    ref: "image",
                    staticClass: "el-upload-list__item-thumbnail",
                    attrs: {
                      src: _vm.define.comUrl + _vm.imageUrl,
                      "preview-src-list": _vm.fileList,
                      "z-index": 10000,
                    },
                  }),
                  _c("span", { staticClass: "el-upload-list__item-actions" }, [
                    _c(
                      "span",
                      {
                        staticClass: "el-upload-list__item-preview",
                        on: {
                          click: function ($event) {
                            return _vm.handlePictureCardPreview()
                          },
                        },
                      },
                      [_c("i", { staticClass: "el-icon-zoom-in" })]
                    ),
                    !_vm.disabled
                      ? _c(
                          "span",
                          {
                            staticClass: "el-upload-list__item-delete",
                            on: {
                              click: function ($event) {
                                return _vm.handleRemove()
                              },
                            },
                          },
                          [_c("i", { staticClass: "el-icon-delete" })]
                        )
                      : _vm._e(),
                  ]),
                ],
                1
              ),
            ]
          )
        : _c(
            "el-upload",
            {
              staticClass: "img-uploader",
              attrs: {
                action: _vm.define.comUploadUrl + "/" + _vm.type,
                "show-file-list": false,
                "on-success": _vm.handleSuccess,
                headers: _vm.uploadHeaders,
                accept: "image/*",
              },
            },
            [
              _c("div", { staticClass: "icon-box" }, [
                _c("i", { staticClass: "el-icon-plus img-uploader-icon" }),
                _vm.tip
                  ? _c("p", { staticClass: "upload-tip" }, [
                      _vm._v(_vm._s(_vm.tip)),
                    ])
                  : _vm._e(),
              ]),
            ]
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }