var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "SNOWSAmount" },
    [
      _c(
        "el-input-number",
        _vm._g(
          _vm._b(
            {
              model: {
                value: _vm.innerValue,
                callback: function ($$v) {
                  _vm.innerValue = $$v
                },
                expression: "innerValue",
              },
            },
            "el-input-number",
            _vm.$attrs,
            false
          ),
          _vm.$listeners
        )
      ),
      _vm.showChinese
        ? _c("div", { staticClass: "explain-text" }, [
            _vm._v("大写：" + _vm._s(_vm.chinese)),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }