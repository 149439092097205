var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "keep-alive",
    { attrs: { include: _vm.cachedViews } },
    [_c("router-view", { staticClass: "avue-view" })],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }