var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "avue-contail",
      class: { "avue--collapse": _vm.keyCollapse },
    },
    [
      _vm.isShow
        ? _c(
            "div",
            { ref: "avueHeader", staticClass: "avue-header" },
            [_c("top", { ref: "top" })],
            1
          )
        : _vm._e(),
      _c(
        "div",
        { staticClass: "avue-layout", style: { paddingLeft: _vm.paddingLeft } },
        [
          _vm.isShow
            ? _c(
                "div",
                { staticClass: "avue-left" },
                [
                  _c("sidebar"),
                  _c("hamburger", {
                    staticClass: "hamburger-container",
                    attrs: { "is-active": _vm.isCollapse },
                    on: { toggleClick: _vm.showCollapse },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "div",
            { staticClass: "avue-main" },
            [
              _vm.isShow ? _c("tags") : _vm._e(),
              _c("shortcutMenu"),
              _c(
                "transition",
                { attrs: { name: "fade-scale" } },
                [
                  _c("search", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.isSearch,
                        expression: "isSearch",
                      },
                    ],
                    staticClass: "avue-view",
                  }),
                ],
                1
              ),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.isSearch,
                      expression: "!isSearch",
                    },
                  ],
                  staticStyle: {
                    height: "calc(100vh - 100px)",
                    "overflow-y": "auto",
                    "overflow-x": "hidden",
                    padding: "10px",
                    background: "#f4f5f6",
                    "box-sizing": "border-box",
                  },
                  attrs: { id: "avue-view" },
                },
                [
                  _c(
                    "keep-alive",
                    [_vm.$route.meta.keepAlive ? _c("router-view") : _vm._e()],
                    1
                  ),
                  !_vm.$route.meta.keepAlive ? _c("router-view") : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _c("div", { staticClass: "avue-shade", on: { click: _vm.showCollapse } }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }