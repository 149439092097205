var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "test-form" },
    [
      _c("parser", {
        attrs: { "form-conf": _vm.formConf },
        on: { submit: _vm.submitForm1 },
      }),
      _c("parser", {
        key: _vm.key2,
        attrs: { "form-conf": _vm.formConf },
        on: { submit: _vm.submitForm2 },
      }),
      _c("el-button", { on: { click: _vm.change } }, [
        _vm._v("\n    change\n  "),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }